import React from "react"

const ExperienceItem = ({
  startDate,
  endDate,
  company,
  title,
  description,
}) => {
  return (
    <div className="p-4 md:flex items-start">
      <div className="general w-full md:w-48">
        <div className="time px-2 border-brand-300 border rounded-full bg-brand-100 relative font-medium dark:bg-brand-300 dark:border-brand-400 dark:text-brand-700">
          {startDate} - {endDate}
        </div>
        <h6 className="font-sans font-bold text-lg mt-2 mb-0 leading-tight">
          {company}
        </h6>
        <p className="m-0 text-sm mt-2 mb-4">{title}</p>
      </div>
      <div className="description mt-4 md:mt-0 md:ml-12 flex-1 text-sm">
        {description.map(parragraph => {
          return <p>{parragraph}</p>
        })}
      </div>
    </div>
  )
}

export default ExperienceItem
