import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "./Index.css"
import ExperienceItem from "../components/experience/ExperienceItem"
import ProjectPreview from "../components/projects/ProjectPreview"

import { DiJavascript1 } from "react-icons/di"
import { DiReact } from "react-icons/di"
import { DiCss3 } from "react-icons/di"
import { DiHtml5 } from "react-icons/di"

import { DiNodejsSmall } from "react-icons/di"
import { DiMysql } from "react-icons/di"
import { DiGo } from "react-icons/di"
import { DiMongodb } from "react-icons/di"

import { DiGit } from "react-icons/di"
import { DiDocker } from "react-icons/di"
import { DiAws } from "react-icons/di"

import Avatar from "../components/general/Avatar"

const IndexPage = () => {
  const { allSitePage, resume } = useStaticQuery(graphql`
    query IndexPage {
      allSitePage(
        limit: 3
        sort: { fields: context___updatedAt, order: DESC }
        filter: { isCreatedByStatefulCreatePages: { eq: false } }
      ) {
        edges {
          node {
            path
            context {
              id
              title
              roles
              rawDescription {
                children {
                  text
                }
              }
            }
          }
        }
      }

      resume: file(relativePath: { eq: "resume.pdf" }) {
        publicURL
      }
    }
  `)

  return (
    <Layout>
      <SEO
        title="Home"
        description="I’m a developer, and Javascript enthusiast. I work at IBM as a
            Full Stack Developer. Anything you might want to know about me and
            more is here."
      />
      <div className="md:flex md:justify-between">
        <div>
          <h1 className="text-gray-900 dark:text-brand-500 font-sans text-4xl mb-10 font-bold leading-tight">
            Hey, I'm Miguel Gallardo
          </h1>
          <p className="max-w-lg">
            I’m a developer, and Javascript enthusiast. I work at IBM as a Full
            Stack Developer. Anything you might want to know about me and more
            is here.
          </p>
        </div>
        <Avatar></Avatar>
      </div>
      <h4 className="text-gray-800 dark:text-brand-400 text-2xl font-sans font-bold mb-4">
        My skills
      </h4>
      <p>
        Throughout the past years I've gained both formal and practical
        knowledge from my Computer Science courses and projects I've worked on,
        and I've dedicated a lot of my free time to improve my skills and apply
        them to real world scenarios.
      </p>
      <div className="flex justify-between flex-wrap lg:flex-nowrap mt-6">
        <div className="w-full lg:w-auto">
          <h5 className="text-xl text-left lg:text-center font-bold mb-4">
            Front End
          </h5>
          <ul className="p-0 m-0 grid grid-cols-4 lg:grid-cols-2">
            <li className="flex flex-wrap justify-center mb-4">
              <DiJavascript1 className="w-10 h-10 fill-curent"></DiJavascript1>
              <span className="block w-full text-center text-sm">
                Javascript
              </span>
            </li>
            <li className="flex flex-wrap justify-center mb-4">
              <DiReact className="w-10 h-10 fill-curent"></DiReact>
              <span className="block w-full text-center text-sm">React JS</span>
            </li>
            <li className="flex flex-wrap justify-center mb-4">
              <DiCss3 className="w-10 h-10 fill-curent"></DiCss3>
              <span className="block w-full text-center text-sm">CSS</span>
            </li>
            <li className="flex flex-wrap justify-center mb-4">
              <DiHtml5 className="w-10 h-10 fill-curent"></DiHtml5>
              <span className="block w-full text-center text-sm">HTML</span>
            </li>
          </ul>
        </div>
        <div className="w-full lg:w-auto">
          <h5 className="text-xl text-left lg:text-center font-bold mb-4">
            Back End
          </h5>
          <ul className="p-0 m-0 grid grid-cols-4 lg:grid-cols-2">
            <li className="flex flex-wrap justify-center mb-4">
              <DiNodejsSmall className="w-10 h-10 fill-curent"></DiNodejsSmall>
              <span className="block w-full text-center text-sm">Node JS</span>
            </li>
            <li className="flex flex-wrap justify-center mb-4">
              <DiGo className="w-10 h-10 fill-curent"></DiGo>
              <span className="block w-full text-center text-sm">Go</span>
            </li>
            <li className="flex flex-wrap justify-center mb-4">
              <DiMysql className="w-10 h-10 fill-curent"></DiMysql>
              <span className="block w-full text-center text-sm">MySQL</span>
            </li>
            <li className="flex flex-wrap justify-center mb-4">
              <DiMongodb className="w-10 h-10 fill-curent"></DiMongodb>
              <span className="block w-full text-center text-sm">MongoDB</span>
            </li>
          </ul>
        </div>
        <div className="w-full lg:w-auto">
          <h5 className="text-xl text-left lg:text-center font-bold mb-4">
            Tools
          </h5>
          <ul className="p-0 m-0 grid grid-cols-4 lg:grid-cols-2">
            <li className="flex flex-wrap justify-center mb-4">
              <DiGit className="w-10 h-10 fill-curent"></DiGit>
              <span className="block w-full text-center text-sm">Git</span>
            </li>
            <li className="flex flex-wrap justify-center mb-4">
              <DiDocker className="w-10 h-10 fill-curent"></DiDocker>
              <span className="block w-full text-center text-sm">Docker</span>
            </li>
            <li className="flex flex-wrap justify-center mb-4">
              <DiAws className="w-10 h-10 fill-curent"></DiAws>
              <span className="block w-full text-center text-sm">AWS</span>
            </li>
          </ul>
        </div>
      </div>
      <h4 className="text-gray-800 dark:text-brand-400 text-2xl font-sans mt-8 font-bold mb-4">
        My projects
      </h4>
      <p>
        I'm always looking for new things to build and grow my skillset with.
        Take a look at some of my projects.
      </p>
      {allSitePage.edges.map(({ node }) => {
        return (
          <ProjectPreview
            link={node.path}
            title={node.context.title}
            description={
              node.context.rawDescription
                ? node.context.rawDescription[0].children[0].text
                : ""
            }
          ></ProjectPreview>
        )
      })}
      <h4 className="text-gray-800 dark:text-brand-400 text-2xl font-sans mt-8 font-bold mb-4">
        My Experience
      </h4>
      <p>
        Here, you'll find a brief summarry of my work experience. If you want to
        take a look at my complete Resume, you can download it in full{" "}
        <a
          href={resume.publicURL}
          download
          className="font-medium underline text-gray-900 dark:text-brand-400"
        >
          here.
        </a>
      </p>
      <div className="border-l border-brand-300 mb-8 relative">
        <ExperienceItem
          company="Anyfin"
          startDate="Mar 2022"
          endDate="Present"
          title="Full Stack Engineer"
          description={[
            "I work at Anyfin, focusing on increasing customer conversions for our main product (Loan refinancing) through implementing product designs and setting up AB tests using Typescript, React and React Native.",
          ]}
        ></ExperienceItem>
        <ExperienceItem
          company="IBM"
          startDate="Feb 2021"
          endDate="Mar 2022"
          title="Full Stack Developer"
          description={[
            "I work on the IBM CIO, focusing mainly on the development and continuous improvement of internal systems that are used by key decision makers inside the company. Some of the things I've worked on include developing web applications with Angular and Python and improving internal testing within my team.",
          ]}
        ></ExperienceItem>
        <ExperienceItem
          company="Inowu"
          startDate="Nov 2018"
          endDate="Present"
          title="Full Stack Engineer"
          description={[
            "Developed web applications using React as a Front End Framework. Integrating with external services like Stripe, Sendgrid, Firebase, and Vimeo.",
            "Development of RESTful APIs using Node JS, Express, and database systems like MongoDB and MySQL. Serverless REST APIs using AWS Lambda, RDS, or DynamoDB and API Gateway.",
          ]}
        ></ExperienceItem>
        <ExperienceItem
          company="Especialidades Médicas Tepepan"
          startDate="Aug 2018"
          endDate="Nov 2018"
          title="Front End Developer"
          description={[
            "Developed an online calendar tool that automates medical appointment booking using React and Firebase. Designed and developed a static website using Gatsby JS.",
          ]}
        ></ExperienceItem>
      </div>
    </Layout>
  )
}
export default IndexPage
