import { Link } from "gatsby"
import React from "react"

const ProjectPreview = ({ title, description, link }) => {
  return (
    <Link
      to={link}
      className="mb-4 p-0 border border-gray-300 rounded shadow-sm flex"
    >
      <div className="p-4">
        <h5 className="mb-3 font-sans font-bold text-lg">{title} </h5>
        <p className="text-sm mb-0">{description}</p>
      </div>
    </Link>
  )
}

export default ProjectPreview
