import React, { useContext } from "react"
import { ThemeContext } from "./ThemeContext"

import GatsbyImage from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"

const Avatar = () => {
  const data = useStaticQuery(graphql`
    query AvatarQuery {
      darkImage: file(relativePath: { eq: "Mike_Blanco_Rosa.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            tracedSVG
            aspectRatio
            src
            srcSet
            sizes
          }
        }
      }

      lightImage: file(relativePath: { eq: "Mike_Negro.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            tracedSVG
            aspectRatio
            src
            srcSet
            sizes
          }
        }
      }
    }
  `)

  const { theme } = useContext(ThemeContext)

  return (
    <GatsbyImage
      fluid={
        theme === "light"
          ? data.lightImage.childImageSharp.fluid
          : data.darkImage.childImageSharp.fluid
      }
      className="max-w-xs mx-auto w-full m-8 p-4 md:w-48 md:h-48 md:m-0 md:ml-8 md:p-0"
    ></GatsbyImage>
  )
}

export default Avatar
